/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

import './src/styles/normalize.css'
import './src/styles/global.css'
import './src/styles/typography.css'
import './src/styles/helpers.css'
import './src/styles/container.css'
import './src/styles/section.css'
import './src/styles/header.css'
import './src/styles/button.css'
import './src/styles/footer.css'
import './src/styles/form.css'
import './src/styles/animations.css'
import './src/styles/horizontal-industries.css'
import './src/styles/vertical-gallery.css'
import './src/styles/home.css'
import './src/styles/company.css'
import './src/styles/careers.css'
import './src/styles/new-product-intro.css'
import './src/styles/modal.css'
import './src/styles/certification.css'

import Modal from 'react-modal'

Modal.setAppElement('#___gatsby')
